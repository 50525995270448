<!--
  服务的详情 表单形式
 -->
<template>
  <div class="detail">
    <detail-header title="服务详情" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <!-- 自定义表单 -->
        <custom-form
          ref="customForm"
          mode="static"
          :formModel="fieldList"
          :formData="formData">
          <custom-form-item
            v-for="item in fieldList"
            :key="item.column"
            :item="item"
            :value.sync="formData[item.column]">
          </custom-form-item>
        </custom-form>
        <template>
          <el-row type="flex" class="tw-mb-20px">
            <div
              style="flex: 0 0 180px; color: #606266;"
              class=" tw-leading-32px tw-px-10px">
              驳回原因
            </div>
            <div>
              {{ formData.reason }}
            </div>
          </el-row>
          <!-- 已驳回状态下显示重新提交按钮 -->
          <el-row v-if="globalVar.SERVICE_STATUS_2">
            <el-button
              type="primary"
              size="small"
              @click="onCreate">
              重新发布
            </el-button>
          </el-row>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import customForm from '@/components/custom-form/index'
import customFormItem from '@/components/custom-form/custom-form-item'
export default {
  data () {
    return {
      // 页面loading
      loading: false,
      btnLoading: false,
      // 字段数组
      fieldList: [],
      // 表单数据
      formData: {}
    }
  },
  computed: {
    // 服务Id
    id () {
      return this.$route.params.id
    }
  },
  components: {
    customForm,
    customFormItem
  },
  created () {
    this.getServiceDetail()
  },
  methods: {
    getServiceDetail () {
      this.loading = true
      api.getServiceDetail(this.id).then(res => {
        if (res.data.code === 0) {
          console.log('服务详情', res.data.data)
          this.fieldList = res.data.data.fields
          this.formData = res.data.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 发布服务
    onCreate () {
      const { href } = this.$router.resolve({
        path: '/service-create'
      })
      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;

      .add_btn_box {
        padding-left: 180px;
      }
    }
  }
}
</style>
